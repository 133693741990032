import React from 'react';
import { Text, ButtonIcon, ButtonIconVariant, Tooltip } from '@incodetech/ui';
import { useTranslation } from 'react-i18next';
import { useCopyToClipBoard } from '../../hooks/useCopyToClipboard';
import './CopyData.scss';

type Props = {
  name: string;
  value: string;
};

function CopyData({ name, value }: Props) {
  const { copied, handleCopy } = useCopyToClipBoard();
  const { t } = useTranslation();
  return (
    <Text tag="p" className="copy-data">
      <Text tag="span">{t(`copy.label.${name}`)}</Text>
      <div className="data-to-copy">
        <Text tag="span" classes="generated">
          {value}
        </Text>
        <Tooltip
          content={
            copied?.[name]
              ? t(`tooltipNotifications.${name}.copied`)
              : t(`tooltipNotifications.${name}.copy`)
          }
        >
          <ButtonIcon
            variant={ButtonIconVariant.COPY}
            onClick={() => handleCopy(value, name)}
          />
        </Tooltip>
      </div>
    </Text>
  );
}

export default CopyData;
