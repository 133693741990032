import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import {
  ModalDialog,
  Button,
  Select,
  Input,
  Checkbox,
  NotificationBox,
  Textarea,
} from '@incodetech/ui';
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  AuthClientGrantTypes,
  AuthClientMethods,
  AuthClientScopes,
  ClientRegistrationData,
  ClientRegistrationFormData,
  IdentityProviders,
  PublicKey,
  RegistrationKeys,
} from '../../../types/clientRegistration';
import './ClientConfigurationModal.scss';
import AuthConfigurationInput from '../../Authentication/components/AuthConfigurationInput';
import useGeneratePublicKey from '../../../hooks/useGeneratePublicKey';
import useGetPublicKey from '../../../hooks/useGetPublicKey';
import { isValidHttpUrl } from '../../../utils/utils';

interface ClientConfigurationModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  handleFormSubmit: SubmitHandler<ClientRegistrationFormData>;
  formData: ClientRegistrationData;
  editing: boolean;
  serverError: string;
}

const defaultFormValue: ClientRegistrationFormData = {
  registrationId: '',
  clientId: '',
  clientSecret: '',
  clientAuthenticationMethod: AuthClientMethods.CLIENT_SECRET_BASIC,
  authorizationGrantType: AuthClientGrantTypes.AUTHORIZATION_CODE,
  redirectUri: '',
  scopes: [],
  providerDetails: {
    authorizationUri: '',
    tokenUri: '',
    jwkSetUri: '',
    issuerUri: '',
    userInfoUri: '',
    userNameAttributeName: '',
  },
  clientName: '',
  identityProvider: IdentityProviders.OKTA,
  adminRole: [{ value: '' }],
  helpDeskRole: [{ value: '' }],
  keyType: RegistrationKeys.CLIENT_SECRET,
  keysId: '',
  publicKey: '',
};

export const ClientConfigurationModal: React.FC<
  ClientConfigurationModalProps
> = ({
  isOpen,
  onRequestClose,
  handleFormSubmit,
  formData,
  editing,
  serverError,
}) => {
  const { t } = useTranslation();
  const [publicKeyDate, setPublicKeyDate] = useState<number | null>(null);
  const methods = useForm<ClientRegistrationFormData>({
    values: {
      ...defaultFormValue,
      ...formData,
      ...(editing &&
        formData &&
        Object.keys(formData?.roleMapping ?? {}).length > 0 && {
          adminRole: formData.roleMapping?.ADMIN?.map(role => ({
            value: role,
          })),
          helpDeskRole: formData.roleMapping?.HELP_DESK_STAFF?.map(role => ({
            value: role,
          })),
        }),
    },
  });

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    clearErrors,
    formState: { errors },
  } = methods;

  const { mutateAsync: generatePublicKey } = useGeneratePublicKey();
  const { mutateAsync: getPublicKey } = useGetPublicKey();
  useEffect(() => {
    const getPublicKeyData = async () => {
      if (!formData.keysId) return;
      setValue('keyType', RegistrationKeys.PUBLIC_KEY);
      const publicKeyData = await getPublicKey(formData.keysId);
      setValue('publicKey', publicKeyData.publicKey);
      setValue('keysId', publicKeyData.id);
      setPublicKeyDate(publicKeyData.createdAt);
    };
    getPublicKeyData();
  }, [formData]);

  const keyTypeWatched = watch('keyType');

  const handleChangeScopes = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;

    if (checked) {
      setValue('scopes', [...watch('scopes'), name as AuthClientScopes]);
    } else {
      setValue(
        'scopes',
        watch('scopes')?.filter(item => item !== name),
      );
    }
  };

  const generateNewPublicKey = async () => {
    const publicKeyData = await generatePublicKey();
    setValue('publicKey', publicKeyData.publicKey);
    setValue('keysId', publicKeyData.id);
    setPublicKeyDate(publicKeyData.createdAt);
  };

  const afterClose = () => {
    setPublicKeyDate(null);
    reset();
  };

  return (
    <ModalDialog
      isOpen={isOpen}
      closeModal={onRequestClose}
      afterModalClose={afterClose}
      shouldCloseOnOverlayClick={false}
      modalTitle={t('clientRegistration.form.title')}
      classes="client-configuration-modal"
    >
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(data => {
            handleFormSubmit(data);
            clearErrors();
          })}
          className="configuration-form"
        >
          <div className="auth-configuration-content">
            <div className="input-wrapper">
              <Select
                id="identityProvider"
                {...register('identityProvider', {
                  required: t('authConfiguration.form.requiredField'),
                })}
                variant="default"
                label={t('clientRegistration.form.identityProvider')}
                options={Object.values(IdentityProviders).map(method => ({
                  value: method,
                  label: method,
                }))}
              />
            </div>
            <div className="input-wrapper">
              <Input
                id="name"
                label={t('authConfiguration.form.name')}
                variant="full"
                {...register('clientName', {
                  required: t('authConfiguration.form.requiredField'),
                })}
                error={errors.clientName?.message}
              />
            </div>
            <div className="input-wrapper">
              <Input
                id="clientId"
                label={t('clientRegistration.form.clientId')}
                variant="full"
                {...register('clientId', {
                  required: t('authConfiguration.form.requiredField'),
                })}
                error={errors.clientId?.message}
              />
            </div>
            <div className="input-wrapper">
              <Select
                id="keyType"
                {...register('keyType', {
                  required: t('authConfiguration.form.requiredField'),
                })}
                variant="default"
                error={errors?.keysId?.message}
                label={t('clientRegistration.form.keyType')}
                options={Object.values(RegistrationKeys).map(method => ({
                  value: method,
                  label: t(`clientRegistration.form.${method}`),
                }))}
              />
            </div>
            {keyTypeWatched === RegistrationKeys.CLIENT_SECRET && (
              <div className="input-wrapper">
                <Input
                  id="clientSecret"
                  label={t('clientRegistration.form.clientSecret')}
                  variant="full"
                  {...register('clientSecret', {
                    required: t('authConfiguration.form.requiredField'),
                  })}
                  error={errors.clientSecret?.message}
                />
              </div>
            )}
            {keyTypeWatched === RegistrationKeys.PUBLIC_KEY && (
              <Input
                id="keysId"
                {...register('keysId', {
                  required: t('clientRegistration.form.keysIdError'),
                })}
                hidden
              />
            )}
            {keyTypeWatched === RegistrationKeys.PUBLIC_KEY &&
              publicKeyDate && (
                <>
                  <div className="input-wrapper">
                    <Textarea
                      id="publicKey"
                      {...register('publicKey')}
                      disabled
                      rows={6}
                      label={t('clientRegistration.form.publicKey')}
                      variant="full"
                    />
                    <div className="date-wrapper">
                      {publicKeyDate
                        ? format(
                            new Date(publicKeyDate),
                            'MMM d yyyy, hh:mm:ss a',
                          )
                        : ''}
                    </div>
                  </div>
                </>
              )}

            {keyTypeWatched === RegistrationKeys.PUBLIC_KEY && (
              <div className="input-wrapper">
                <Button
                  type="button"
                  label={t('clientRegistration.form.generatePublicKeyButton')}
                  variant="white"
                  withIcon
                  iconVariant="iconBtnAdd"
                  onClick={generateNewPublicKey}
                />
              </div>
            )}

            <div className="input-wrapper">
              <Select
                id="clientAuthenticationMethod"
                {...register('clientAuthenticationMethod', {
                  required: t('authConfiguration.form.requiredField'),
                })}
                variant="default"
                error={errors?.clientAuthenticationMethod?.message}
                label={t('clientRegistration.form.clientAuthenticationMethod')}
                options={Object.values(AuthClientMethods).map(method => ({
                  value: method,
                  label: method,
                }))}
              />
            </div>
            <div className="input-wrapper">
              <Select
                id="authorizationGrantType"
                {...register('authorizationGrantType', {
                  required: t('authConfiguration.form.requiredField'),
                })}
                variant="default"
                error={errors?.authorizationGrantType?.message}
                label={t('clientRegistration.form.authorizationGrantType')}
                options={Object.values(AuthClientGrantTypes).map(method => ({
                  value: method,
                  label: method,
                }))}
              />
            </div>
            {editing && (
              <div className="input-wrapper">
                <Input
                  id="redirectUri"
                  label={t('clientRegistration.form.redirectUri')}
                  variant="full"
                  {...register('redirectUri', {
                    required: t('authConfiguration.form.requiredField'),
                    validate: value =>
                      isValidHttpUrl(value)
                        ? true
                        : `${t('clientRegistration.form.validUrlError')}`,
                  })}
                  error={errors.redirectUri?.message}
                />
              </div>
            )}
            <AuthConfigurationInput
              name="adminRole"
              label={t('clientRegistration.form.adminRole')}
              errorMessage={t('authConfiguration.form.requiredField')}
            />
            <AuthConfigurationInput
              name="helpDeskRole"
              label={t('clientRegistration.form.helpDeskRole')}
              errorMessage={t('authConfiguration.form.requiredField')}
            />
            <div className="input-wrapper">
              <label className="input-label">
                {t('authConfiguration.form.scopes')}
              </label>
              {['openid', 'profile', 'email', 'address', 'phone'].map(item => (
                <div className="input-group" key={item}>
                  <Checkbox
                    label={item}
                    id={item}
                    name={item}
                    checked={
                      watch('scopes')?.includes(item as AuthClientScopes) ||
                      item === 'openid'
                    }
                    disabled={item === 'openid'}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChangeScopes(e);
                    }}
                  />
                </div>
              ))}
            </div>
            <div className="input-wrapper">
              <Input
                id="providerDetails.tokenUri"
                label={t('clientRegistration.form.tokenUri')}
                variant="full"
                {...register('providerDetails.tokenUri', {
                  required: t('authConfiguration.form.requiredField'),
                  validate: value =>
                    isValidHttpUrl(value)
                      ? true
                      : `${t('clientRegistration.form.validUrlError')}`,
                })}
                error={errors.providerDetails?.tokenUri?.message}
              />
            </div>
            <div className="input-wrapper">
              <Input
                id="providerDetails.jwkSetUri"
                label={t('clientRegistration.form.jwkSetUri')}
                variant="full"
                {...register('providerDetails.jwkSetUri', {
                  required: t('authConfiguration.form.requiredField'),
                  validate: value =>
                    isValidHttpUrl(value)
                      ? true
                      : `${t('clientRegistration.form.validUrlError')}`,
                })}
                error={errors.providerDetails?.jwkSetUri?.message}
              />
            </div>
            <div className="input-wrapper">
              <Input
                id="providerDetails.authorizationUri"
                label={t('clientRegistration.form.authorizationUri')}
                variant="full"
                {...register('providerDetails.authorizationUri', {
                  required: t('authConfiguration.form.requiredField'),
                  validate: value =>
                    isValidHttpUrl(value)
                      ? true
                      : `${t('clientRegistration.form.validUrlError')}`,
                })}
                error={errors.providerDetails?.authorizationUri?.message}
              />
            </div>
            <div className="input-wrapper">
              <Input
                id="providerDetails.issuerUri"
                label={t('clientRegistration.form.issuerUri')}
                variant="full"
                {...register('providerDetails.issuerUri', {
                  required: t('authConfiguration.form.requiredField'),
                  validate: value =>
                    isValidHttpUrl(value)
                      ? true
                      : `${t('clientRegistration.form.validUrlError')}`,
                })}
                error={errors.providerDetails?.issuerUri?.message}
              />
            </div>
            <div className="input-wrapper">
              <Input
                id="providerDetails.userInfoUri"
                label={t('clientRegistration.form.userInfoUri')}
                variant="full"
                {...register('providerDetails.userInfoUri', {
                  required: t('authConfiguration.form.requiredField'),
                  validate: value =>
                    isValidHttpUrl(value)
                      ? true
                      : `${t('clientRegistration.form.validUrlError')}`,
                })}
                error={errors.providerDetails?.userInfoUri?.message}
              />
            </div>
            <div className="input-wrapper">
              <Input
                id="providerDetails.userNameAttributeName"
                label={t('clientRegistration.form.userNameAttributeName')}
                variant="full"
                {...register('providerDetails.userNameAttributeName', {
                  required: t('authConfiguration.form.requiredField'),
                })}
                error={errors.providerDetails?.userNameAttributeName?.message}
              />
            </div>
          </div>
          {serverError && (
            <NotificationBox variant="error" text={serverError} />
          )}

          <div className="separator" />
          <div className="button-container">
            <Button
              label={t('authConfiguration.form.buttonSave')}
              type="submit"
              variant="blue"
              classes="modal-button"
            />
          </div>
        </form>
      </FormProvider>
    </ModalDialog>
  );
};

export default ClientConfigurationModal;
