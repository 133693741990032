import CryptoJS from 'crypto-js';
// import { toast } from 'react-toastify';

import { client } from '../http/api';
import { AUTH_DATA } from '../constants/constants';

export const generateSHA256Hash = (text: string) => {
  return CryptoJS.SHA256(text)
    .toString(CryptoJS.enc.Base64)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
};

export const generateRandomString = (length: number): string => {
  const characters: string =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomString: string = '';

  for (let i: number = 0; i < length; i++) {
    const randomIndex: number = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }

  return randomString;
};

const baseAppUrl = window.location.origin;
const redirectUri = `${baseAppUrl}/authorized`;

export type AuthData = {
  clientId: string;
  codeVerifier: string;
  nonce: string;
  state: string;
};

type AuthResponseData = {
  access_token: string;
  expires_in: 299;
  id_token: string;
  scope: string;
  token_type: string;
};

export const getLoginUrl = (client: string) => {
  const scopes = encodeURIComponent('openid client.read client.create');
  const responseType = 'code';
  const state = generateRandomString(10);
  const nonce = generateRandomString(10);
  const codeVerifier = generateRandomString(43);
  const codeChallenge = generateSHA256Hash(codeVerifier);
  const codeChallengeMethod = 'S256';

  const authorizeData: AuthData = {
    clientId: client,
    codeVerifier,
    nonce,
    state,
  };

  localStorage.setItem(AUTH_DATA, JSON.stringify(authorizeData));
  // @ts-ignore
  const oidcUrl = `${
    import.meta.env.VITE_SERVER
  }/oauth2/authorize?client_id=${client}&redirect_uri=${redirectUri}&scope=${scopes}&response_type=${responseType}&code_challenge_method=${codeChallengeMethod}&code_challenge=${codeChallenge}&state=${state}&nonce=${nonce}`;

  return oidcUrl;
};

export async function getTokenWithCode({
  code,
  clientId,
  codeVerifier,
}: {
  code: string;
  clientId: string;
  codeVerifier: string;
}) {
  const params = new URLSearchParams({
    code,
    client_id: clientId,
    redirect_uri: redirectUri,
    code_verifier: codeVerifier,
    grant_type: 'authorization_code',
  }).toString();

  localStorage.removeItem(AUTH_DATA);
  const response = await client.post<AuthResponseData>(
    `/oauth2/token?${params}`,
  );
  return response.data;
}

export const sortAlphabetically = (a: any, b: any): number => {
  if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return -1;
  }
  if (a.name.toLowerCase() > b.name.toLowerCase()) {
    return 1;
  }
  return 0;
};

export const camelToStartCase = (text: string): string => {
  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

// export const setNotification = (
//   message: string,
//   severity: string = 'default',
//   handleOnClose = () => { },
// ): void => {
//   const options = { onClose: handleOnClose, icon: false };
//   switch (severity) {
//     case 'info':
//       toast.info(message, { ...options });
//       break;
//     case 'success':
//       toast.success(message, { ...options });
//       break;
//     case 'warning':
//       toast.warning(message, { ...options });
//       break;
//     case 'error':
//       toast.error(message, { ...options });
//       break;
//     case 'dark':
//       toast.dark(message);
//       break;
//     default:
//       toast(message);
//       break;
//   }
// };
export const isValidHttpUrl = (string: string): boolean => {
  if (string === '') return true;
  let url;
  try {
    url = new URL(string);
    const httpProtocol = url.protocol === 'http:' || url.protocol === 'https:';
    if (httpProtocol === false) return false;

    const [urlToTest] = string.split('?');

    const pattern = new RegExp(
      '^([a-zA-Z]+:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', // fragment locator
      'i',
    );

    return !!pattern.test(urlToTest);
  } catch (e) {
    return false;
  }
};
