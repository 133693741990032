export const AuthClientMethods = {
  CLIENT_SECRET_BASIC: 'client_secret_basic',
  CLIENT_SECRET_POST: 'client_secret_post',
  CLIENT_SECRET_JWT: 'client_secret_jwt',
  PRIVATE_KEY_JWT: 'private_key_jwt',
  NONE: 'none',
} as const;

export const AuthClientGrantTypes = {
  AUTHORIZATION_CODE: 'authorization_code',
  REFRESH_TOKEN: 'refresh_token',
  CLIENT_CREDENTIALS: 'client_credentials',
} as const;

export type AuthClientGrantType =
  (typeof AuthClientGrantTypes)[keyof typeof AuthClientGrantTypes];

export type AuthClientMethod =
  (typeof AuthClientMethods)[keyof typeof AuthClientMethods];

export type AuthClientScopes =
  | 'openid'
  | 'profile'
  | 'email'
  | 'address'
  | 'phone';

type RoleMapping = {
  ADMIN: string[];
  HELP_DESK_STAFF: string[];
};
export interface ClientRegistration {
  registrationId: string;
  clientId: string;
  clientSecret: string;
  clientAuthenticationMethod: AuthClientMethod;
  authorizationGrantType: AuthClientGrantType;
  redirectUri: string;
  scopes: Array<AuthClientScopes>;
  providerDetails: {
    authorizationUri: string;
    tokenUri: string;
    jwkSetUri: string;
    issuerUri: string;
    userInfoUri: string;
    userNameAttributeName: string;
  };
  clientName: string;
  identityProvider: IdentityProvider;
  keysId: string;
}

export interface ClientRegistrationFormData extends ClientRegistration {
  adminRole: { value: string }[];
  helpDeskRole: { value: string }[];
  keyType: RegistrationKey;
  publicKey: string;
}

export interface ClientRegistrationData extends ClientRegistration {
  roleMapping: RoleMapping;
}

export interface ClientRegistrationResponse {
  registrations: ClientRegistrationData[];
}

export type ClientRegistrationModalData = {
  isOpen: boolean;
  formData: ClientRegistrationData;
  editing: boolean;
};

export const IdentityProviders = {
  OKTA: 'OKTA',
  ENTRA: 'ENTRA',
};

export type IdentityProvider =
  (typeof IdentityProviders)[keyof typeof IdentityProviders];

export const RegistrationKeys = {
  PUBLIC_KEY: 'PUBLIC_KEY',
  CLIENT_SECRET: 'CLIENT_SECRET',
};
export type RegistrationKey =
  (typeof RegistrationKeys)[keyof typeof RegistrationKeys];

export type PublicKey = {
  id: string;
  createdAt: number;
  publicKey: string;
};
