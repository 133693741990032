import { useMutation, useQueryClient } from '@tanstack/react-query';
import { client } from '../http/api';
import { ClientRegistrationData } from '../types/clientRegistration';

async function createClient(data) {
  const response = await client.post<ClientRegistrationData>(
    `/auth/registration`,
    data,
    {
      withCredentials: true,
    },
  );
  return response.data;
}

function useCreateClientRegistration() {
  const client = useQueryClient();
  return useMutation({
    mutationFn: createClient,
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['ClientRegistration'] });
    },
  });
}

export { useCreateClientRegistration };
export default useCreateClientRegistration;
