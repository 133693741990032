import { useMutation, useQueryClient } from '@tanstack/react-query';

import { client } from '../http/api';
import { ClientRegistrationData } from '../types/clientRegistration';

async function updateClient(data) {
  const response = await client.put<ClientRegistrationData>(
    `/auth/registration`,
    data,
    {
      withCredentials: true,
    },
  );
  return response.data;
}

function useUpdateClientRegistration() {
  const client = useQueryClient();
  return useMutation({
    mutationFn: updateClient,
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['ClientRegistration'] });
    },
  });
}

export { useUpdateClientRegistration };
export default useUpdateClientRegistration;
