import { useMutation } from '@tanstack/react-query';

import { client } from '../http/api';
import { PublicKey } from '../types/clientRegistration';

async function getPublicKeyById(id: string) {
  const response = await client.post<PublicKey>(
    `/auth/registration/public-key?id=${id}`,
  );
  return response.data;
}

function useGetPublicKey() {
  return useMutation({
    mutationFn: getPublicKeyById,
  });
}

export { useGetPublicKey };
export default useGetPublicKey;
