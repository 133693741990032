import React from 'react';
import { ModalDialog, Text } from '@incodetech/ui';
import { useTranslation } from 'react-i18next';
import { GeneratedDataModal as GeneratedDataModalType } from '../../../types/authClientConfig';
import CopyData from '../../../components/CopyData/CopyData';

interface ConfirmDeleteModalProps {
  isOpen: boolean;
  closeModal: () => void;
  info: GeneratedDataModalType['info'];
}

const GeneratedConfigurationModal: React.FC<ConfirmDeleteModalProps> = ({
  isOpen,
  closeModal,
  info,
}) => {
  const { t } = useTranslation();

  return (
    <ModalDialog
      isOpen={isOpen}
      closeModal={closeModal}
      modalTitle={t('clientRegistration.generatedModal.title')}
      classes="generated-data-modal"
    >
      <Text tag="p">{t('clientRegistration.generatedModal.message')}</Text>
      <CopyData name="redirectUri" value={info.redirectUri} />
    </ModalDialog>
  );
};

export default GeneratedConfigurationModal;
