import { useMutation } from '@tanstack/react-query';

import { client } from '../http/api';
import { PublicKey } from '../types/clientRegistration';

async function getPublicKey() {
  const response = await client.post<PublicKey>(
    `/auth/registration/public-key`,
  );
  return response.data;
}

function useGeneratePublicKey() {
  return useMutation({
    mutationFn: getPublicKey,
  });
}

export { useGeneratePublicKey };
export default useGeneratePublicKey;
