import React, { useState } from 'react';
import { Card, Button, ButtonIconVariant, ButtonIcon } from '@incodetech/ui';
import { useTranslation } from 'react-i18next';

import {
  useGetClientRegistrations,
  useCreateClientRegistration,
  useUpdateClientRegistration,
  useDeleteRegistrationClient,
} from '../../hooks';
import {
  ClientRegistrationData as ClientRegistrationType,
  ClientRegistrationModalData,
} from '../../types/clientRegistration';
import { ErrorCode } from '../../constants/constants';
import ClientRegistrationModal from './components/ClientConfigurationModal';
import ConfirmDeleteRegistrationModal from './components/ConfirmDeleteRegistrationModal';

import './ClientRegistration.scss';
import { GeneratedDataModal } from '../../types/authClientConfig';
import GeneratedConfigurationModal from './components/GeneratedConfigurationModal';

export type ModalDeleteData = {
  isOpen: boolean;
  registrationId: string;
};

function ClientRegistration() {
  const { t } = useTranslation();
  const { data } = useGetClientRegistrations();
  const { mutateAsync: addClientRegistration } = useCreateClientRegistration();
  const { mutateAsync: editClientRegistration } = useUpdateClientRegistration();
  const { mutateAsync: deleteClientRegistration } =
    useDeleteRegistrationClient();
  const [serverError, setServerError] = useState('');
  const [deleteModalState, setDeleteModalState] = useState<ModalDeleteData>({
    isOpen: false,
    registrationId: '',
  });
  const [modalData, setModalData] = useState<ClientRegistrationModalData>({
    isOpen: false,
    formData: {} as ClientRegistrationType,
    editing: false,
  });
  const [generatedDataModal, setGeneratedDataModal] =
    useState<GeneratedDataModal>({
      isOpen: false,
      info: { redirectUri: '' },
    });

  const submitAuthConfigModal = async formData => {
    try {
      setServerError('');
      const submitData = {
        ...formData,
        scopes: [...formData.scopes, 'openid'],
        roleMapping: {
          ADMIN: formData?.adminRole?.map(role => role.value) ?? [],
          HELP_DESK_STAFF:
            formData?.helpDeskRole?.map(role => role.value) ?? [],
        },
      };

      const config = modalData.editing
        ? await editClientRegistration(submitData)
        : await addClientRegistration(submitData);

      if (!modalData.editing) {
        setGeneratedDataModal({
          isOpen: true,
          info: {
            redirectUri: config.redirectUri,
          },
        });
      }

      setModalData({
        isOpen: false,
        formData: {} as ClientRegistrationType,
        editing: false,
      });
    } catch (e) {
      let errorMessage = t('authConfiguration.error');

      if (e?.response?.data?.status === ErrorCode.NON_UNIQUE_CLIENT_NAME) {
        errorMessage = t(`authConfiguration.uniqueName`);
      }
      if ([5006, 5007, 5008].includes(e?.response?.data?.status ?? 0)) {
        errorMessage = t(`authConfiguration.${e.response.data.status}`);
      }

      setServerError(errorMessage);
      return;
    }
  };

  const handleDelete = async (registrationId: string) => {
    await deleteClientRegistration({ registrationId });
    setDeleteModalState({ isOpen: false, registrationId: '' });
  };

  return (
    <div className="client-registration-page">
      <Card title={t('clientRegistration.title')}>
        <div className="table-wrapper">
          <table>
            <thead>
              <tr>
                <th className="clientId">{t('clientRegistration.clientId')}</th>
                <th className="list">{t('clientRegistration.clientName')}</th>
                <th className="list">
                  {t('clientRegistration.clientAuthenticationMethod')}
                </th>
                <th className="list">{t('clientRegistration.issuerUri')}</th>
                <th className="list">{t('clientRegistration.actions')}</th>
              </tr>
            </thead>
            <tbody>
              {data?.registrations?.map(registration => {
                return (
                  <tr key={registration.registrationId}>
                    <td>{registration.registrationId}</td>
                    <td>{registration.clientName}</td>
                    <td>{registration.clientAuthenticationMethod}</td>
                    <td>{registration.providerDetails?.issuerUri}</td>
                    <td>
                      <ButtonIcon
                        variant={ButtonIconVariant.EDIT}
                        classes="auth-button-icon"
                        tooltip={t('authConfiguration.tooltipEdit')}
                        onClick={() => {
                          setModalData({
                            isOpen: true,
                            // @ts-ignore
                            formData: {
                              ...registration,
                            },
                            editing: true,
                          });
                        }}
                      />
                      <ButtonIcon
                        classes="auth-button-icon"
                        variant={ButtonIconVariant.DELETE}
                        tooltip={t('authConfiguration.tooltipDelete')}
                        onClick={() =>
                          setDeleteModalState({
                            isOpen: true,
                            registrationId: registration.registrationId,
                          })
                        }
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Card>
      <div className="card__footer">
        <Button
          label={t('authConfiguration.addNew')}
          variant="white"
          withIcon
          // @ts-ignore
          iconVariant="iconBtnAdd"
          onClick={() => {
            setModalData({
              isOpen: true,
              formData: {} as ClientRegistrationType,
              editing: false,
            });
          }}
        />
      </div>
      <ClientRegistrationModal
        isOpen={modalData.isOpen}
        onRequestClose={() => {
          setModalData({
            isOpen: false,
            formData: {} as ClientRegistrationType,
            editing: false,
          });
          setServerError('');
        }}
        serverError={serverError}
        formData={modalData.formData}
        editing={modalData.editing}
        handleFormSubmit={submitAuthConfigModal}
      />
      <ConfirmDeleteRegistrationModal
        isOpen={deleteModalState.isOpen}
        handleDelete={() => {
          handleDelete(deleteModalState.registrationId);
        }}
        closeModal={() =>
          setDeleteModalState({ isOpen: false, registrationId: '' })
        }
      />
      <GeneratedConfigurationModal
        isOpen={generatedDataModal.isOpen}
        info={generatedDataModal.info}
        closeModal={() =>
          setGeneratedDataModal({
            isOpen: false,
            info: { redirectUri: '' },
          })
        }
      />
    </div>
  );
}

export { ClientRegistration };
export default ClientRegistration;
